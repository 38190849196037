// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import appSettings from "../config/settings"
import { types as sdkTypes, transit } from "./sdkLoader"
import Decimal from "decimal.js"

export const apiBaseUrl = (marketplaceRootURL) => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT
  const useDevApiServer = process.env.NODE_ENV === "development" && !!port

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`
  }

  // Otherwise, use the given marketplaceRootURL parameter or the same domain and port as the frontend
  return marketplaceRootURL ? marketplaceRootURL.replace(/\/$/, "") : `${window.location.origin}`
}

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: (v) => new sdkTypes.BigDecimal(v.toString()),
    reader: (v) => new Decimal(v.value),
  },
]

const serialize = (data) => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose })
}

const deserialize = (str) => {
  return transit.read(str, { typeHandlers })
}

const methods = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
}

// If server/api returns data from SDK, you should set Content-Type to 'application/transit+json'
const request = (path, options = {}) => {
  const url = `${apiBaseUrl()}${path}`
  const { credentials, headers, body, ...rest } = options

  // If headers are not set, we assume that the body should be serialized as transit format.
  const shouldSerializeBody = (!headers || headers["Content-Type"] === "application/transit+json") && body
  const bodyMaybe = shouldSerializeBody ? { body: serialize(body) } : {}

  const fetchOptions = {
    credentials: credentials || "include",
    // Since server/api mostly talks to Marketplace API using SDK,
    // we default to 'application/transit+json' as content type (as SDK uses transit).
    headers: headers || { "Content-Type": "application/transit+json" },
    ...bodyMaybe,
    ...rest,
  }

  return window.fetch(url, fetchOptions).then((res) => {
    const contentTypeHeader = res.headers.get("Content-Type")
    const contentType = contentTypeHeader ? contentTypeHeader.split(";")[0] : null

    if (res.status >= 400) {
      return res.json().then((data) => {
        let e = new Error()
        e = Object.assign(e, data)

        throw e
      })
    }
    if (contentType === "application/transit+json") {
      return res.text().then(deserialize)
    }
    if (contentType === "application/json") {
      return res.json()
    }
    return res.text()
  })
}

// Keep the previous parameter order for the post method.
// For now, only POST has own specific function, but you can create more or use request directly.
const post = (path, body, options = {}) => {
  const requestOptions = {
    ...options,
    method: methods.POST,
    body,
  }

  return request(path, requestOptions)
}

/**
 *
 * @param {string} id external (ours) id of a business registered in DoorDash
 * @returns {Promise<Object>} The response from the DoorDash API.
 */
export const getBusinessDoorDash = (body) => {
  return post("/api/get-dd-business", body)
}

/**
 * @typedef {("initiated"|"pending_external_activation"|"pending_legal_agreement"|"abandoned"|"failed"|"active"|"inactive")} BusinessActivationStatus
 */

/**
 * Creates a new business on DoorDash.
 *
 * @param {Object} payload - The payload containing the business details.
 * @param {string} payload.external_business_id - The external business ID required by DoorDash.
 * @param {string} payload.name - Human readable name for the business. Used when the details of a business are shown to a customer, Dasher, merchant, or support agent.
 * @param {string} payload.description - Short description of the business. Used in the DoorDash Merchant Portal. Maximum length of description is 100 characters.
 * @param {BusinessActivationStatus} [payload.activation_status] - Activation status of the business. Used primarily for the automatic self-serve onboarding flow.
 * @returns {Promise<Object>} The response from the DoorDash API.
 * @throws {Error} If the external business ID or name is missing.
 */
export const createBusinessDoorDash = (body) => {
  return post("/api/create-dd-business", body)
}

/**
 *
 * @param {object} payload
 * @param {string} payload.external_business_id - The external business ID required by DoorDash.
 * @param {string} payload.external_store_id - The external store ID that belongs to the business id
 * @param {string} payload.name - Human readable name for the business. Used when the details of a business are shown to a customer, Dasher, merchant, or support agent.
 * @param {string} payload.address - (non-empty string) The full address of the store, in the order appropriate for your locale, with each element separated by a comma.
 * @param {string} payload.phone_number - (any non empty string, validated by country) Phone number of the store. Used when a customer, Dasher, or support agent needs to contact the store.
 * @returns {Promise<Object>} The response from the DoorDash API.
 * @throws {Error} If the external business ID, external store ID, store name or store address is missing.
 */
export const createStoreDoorDash = (body) => {
  return post("/api/create-dd-store", body)
}

/**
 *
 * @param {object} payload
 * @param {string} payload.external_business_id - The external business ID required by DoorDash.
 * @param {string} payload.external_store_id - The external store ID that belongs to the business id
 * @returns {Promise<Object>} The response from the DoorDash API.
 * @throws {Error} If the external business ID or store ID is missing.
 */
export const getStoreDoorDash = (body) => {
  return post("/api/store-dd-store", body)
}

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = (body) => {
  return post("/api/transaction-line-items", body)
}

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = (body) => {
  return post("/api/initiate-privileged", body)
}

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = (body) => {
  return post("/api/transition-privileged", body)
}

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = (body) => {
  return post("/api/auth/create-user-with-idp", body)
}

export const fetchListingAuthorPrivateData = (listingId) => {
  return post("/api/listing-author-private-data", listingId)
}

export const getDeliveryEstimate = (body) => {
  return post("/api/delivery-estimate", body)
}

export const createDeliveryOrder = (body) => {
  return post("/api/delivery-order", body)
}

export const fetchTransactionRelatedTodayWithGrace = (listingId) => {
  return post("/api/transaction-related-today-with-grace", listingId)
}