/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { oneOf, string } from "prop-types"
import classNames from "classnames"

import { FormattedMessage, intlShape, injectIntl } from "../../util/reactIntl"
import {
  propTypes,
  LISTING_UNIT_TYPES,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from "../../util/types"

import LineItemBookingPeriod from "./LineItemBookingPeriod"
import LineItemBasePriceMaybe from "./LineItemBasePriceMaybe"
import LineItemSubTotalMaybe from "./LineItemSubTotalMaybe"
import LineItemShippingFeeMaybe from "./LineItemShippingFeeMaybe"
import LineItemPickupFeeMaybe from "./LineItemPickupFeeMaybe"
import LineItemCustomerCommissionMaybe from "./LineItemCustomerCommissionMaybe"
import LineItemCustomerCommissionRefundMaybe from "./LineItemCustomerCommissionRefundMaybe"
import LineItemProviderCommissionMaybe from "./LineItemProviderCommissionMaybe"
import LineItemProviderCommissionRefundMaybe from "./LineItemProviderCommissionRefundMaybe"
import LineItemRefundMaybe from "./LineItemRefundMaybe"
import LineItemTotalPrice from "./LineItemTotalPrice"
import LineItemUnknownItemsMaybe from "./LineItemUnknownItemsMaybe"

import css from "./OrderBreakdown.module.css"
import { estimatedCustomerTransaction } from "../OrderPanel/EstimatedCustomerBreakdownMaybe"
import { getProcess } from "../../transactions/transaction"

export const OrderBreakdownComponent = (props) => {
  const {
    rootClassName,
    className,
    userRole,
    transaction,
    booking,
    intl,
    dateType,
    timeZone,
    currency,
    marketplaceName,
  } = props

  const processName = props.processName ?? transaction.attributes.processName
  const isCustomer = userRole === "customer"
  const isProvider = userRole === "provider"
  // * existing process
  // const allLineItems = transaction.attributes.lineItems || []
  // // We'll show only line-items that are specific for the current userRole (customer vs provider)
  // const lineItems = allLineItems.filter((lineItem) => lineItem.includeFor.includes(userRole))
  // const unitLineItem = lineItems.find((item) => LISTING_UNIT_TYPES.includes(item.code) && !item.reversal)
  // // Line-item code that matches with base unit: day, night, hour, item
  // const lineItemUnitType = unitLineItem?.code

  // const hasCommissionLineItem = lineItems.find((item) => {
  //   const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION
  //   const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION
  //   return (hasCustomerCommission || hasProviderCommission) && !item.reversal
  // })
  // * --- end of existing process

  // * new process
  const speculatedTransactionLineItems = useSelector(
    (state) => state.CheckoutPage.speculatedTransaction?.attributes?.lineItems,
  )

  const [lineItems, setLineItems] = useState(transaction?.attributes?.lineItems ?? [])
  const [updateTx, setUpdateTx] = useState(transaction)
  const unitLineItem = lineItems.find((item) => LISTING_UNIT_TYPES.includes(item.code) && !item.reversal)
  // Line-item code that matches with base unit: day, night, hour, item
  const lineItemUnitType = unitLineItem?.code

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const allLineItems = speculatedTransactionLineItems || transaction.attributes.lineItems || []
    const filteredLineItems = allLineItems.filter((lineItem) => lineItem.includeFor.includes(userRole))
    setLineItems(filteredLineItems)
    const process = getProcess(processName ?? transaction.attributes.processName)
    const tx = estimatedCustomerTransaction(
      allLineItems,
      null, // bookingStart,
      null, // bookingEnd,
      lineItemUnitType,
      timeZone,
      // TODO find the process
      process,
      // * and the name
      processName,
      // * could be hardcoded if need be
      currency, // marketplaceCurrency,
    )
    setUpdateTx(tx)
  }, [speculatedTransactionLineItems, transaction, userRole])

  const hasCommissionLineItem = lineItems.find((item) => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal
  })

  const classes = classNames(rootClassName || css.root, className)

  /**
   * OrderBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemShippingFeeMaybe: prints the shipping fee (combining additional fee of
   * additional items into it).
   *
   * LineItemShippingFeeRefundMaybe: prints the amount of refunded shipping fee
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by this template doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  return (
    <div className={classes}>
      <LineItemBookingPeriod booking={booking} code={lineItemUnitType} dateType={dateType} timeZone={timeZone} />

      <LineItemBasePriceMaybe lineItems={lineItems} code={lineItemUnitType} intl={intl} />
      <LineItemShippingFeeMaybe lineItems={lineItems} intl={intl} />
      <LineItemPickupFeeMaybe lineItems={lineItems} intl={intl} />
      <LineItemUnknownItemsMaybe lineItems={lineItems} isProvider={isProvider} intl={intl} />

      <LineItemSubTotalMaybe
        lineItems={lineItems}
        code={lineItemUnitType}
        userRole={userRole}
        intl={intl}
        marketplaceCurrency={currency}
      />
      <LineItemRefundMaybe lineItems={lineItems} intl={intl} marketplaceCurrency={currency} />

      <LineItemCustomerCommissionMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        marketplaceName={marketplaceName}
        intl={intl}
      />
      <LineItemCustomerCommissionRefundMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        marketplaceName={marketplaceName}
        intl={intl}
      />

      <LineItemProviderCommissionMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        marketplaceName={marketplaceName}
        intl={intl}
      />
      <LineItemProviderCommissionRefundMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        marketplaceName={marketplaceName}
        intl={intl}
      />

      {updateTx ? (
        <LineItemTotalPrice transaction={updateTx} isProvider={isProvider} intl={intl} />
      ) : (
        <LineItemTotalPrice transaction={transaction} isProvider={isProvider} intl={intl} />
      )}

      {/* <LineItemTotalPrice transaction={transaction} isProvider={isProvider} intl={intl} /> */}

      {hasCommissionLineItem ? (
        <span className={css.feeInfo}>
          <FormattedMessage id="OrderBreakdown.commissionFeeNote" />
        </span>
      ) : null}
    </div>
  )
}

OrderBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  booking: null,
  dateType: null,
}

OrderBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,
  userRole: oneOf(["customer", "provider"]).isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
}

const OrderBreakdown = injectIntl(OrderBreakdownComponent)

OrderBreakdown.displayName = "OrderBreakdown"

export default OrderBreakdown
